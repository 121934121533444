<template>
    <div>
      <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center justify-content-between">
              <!-- Judul Modal -->
              <h5 class="modal-title">Driver Fulfillment</h5>

              <!-- Wrapper untuk tombol -->
              <div class="d-flex gap-2">
                  <!-- Tombol Download -->
                  <button type="button" class="btn btn-sm btn-primary" v-on:click="download()">
                      Download
                  </button>
                  
                  <!-- Tombol Close -->
                  <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
                      aria-label="Close">
                  </button>
              </div>
          </div>

            <div class="modal-body">
              <!-- :rows="rows"  -->
              <vue-good-table
                  title="list-order-ct"
                  mode="remote"
                  :totalRows="totalRecords"
                  :line-numbers="true"
                  :pagination-options="{
                      enabled: true,
                  }"
                  :isLoading.sync="isLoading"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :columns="columns"
                  :rows="rows"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <a style="color: #ab0635;  "
                      title="Detail"
                      @click.prevent="
                        detailData(props.index, props.row)
                      "
                    >
                      <i class="mdi mdi-eye-outline" style="font-size:16px"></i>
                      
                    </a>
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <modal-driver-fullfilment-detail :showModal="showModalDetail" :data="dataSend"
      @closeModal="closeMyModalDetail"></modal-driver-fullfilment-detail>
    </div>
  </template>
  
  <script>
    import modalDriverFullfilmentDetail from "@/components/modal/modalDriverFullfilmentDetail.vue";

  export default {
    components: {
        "modal-driver-fullfilment-detail": modalDriverFullfilmentDetail
    },
      name:"modalDriverFullfilment",
      emits: ["closeModal","pushData"],
      props: {
        showModal: Boolean,
        data: null,
      },
      watch: {
          showModal: {
          handler(newVal) {
              this.active = newVal;
              const body = document.querySelector("body");
              this.showModal
              ? body.classList.add("modal-open")
              : body.classList.remove("modal-open");
              if(this.showModal==true){
                this.loadItems();
              }
             
            },
          immediate: true,
          deep: true,
          },
      },
      data() {
        return {
            dataSend:{},
            showModalDetail:false,
            closeMyModal:true,
            isLoading: false,
            opens: "center",
            active: this.showModal,
            fetchCompany: [],
            openModalDetail:false,
            totalRecords:0,
            forms:[],
            status:"ASSIGNED",
            columns: [
              {
                label:"Customer Team",
                field: "customer_team",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
              {
                label:"Truck Category",
                field: "truck_category",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Type Truck",
                field: "type_truck",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Total",
                field: "total",
                
              },


              {
                label:"Driver Settle",
                field: "driverSettle",
                
              },
  

              {
                label:"Driver Request",
                field: "driverRequest",
                
              },

              {
                label:"Non Request",
                field: "nonRequest",
                
              },
  
  
  
             


              {
                label:"Action",
                field: "action",
               
              },
              
              
            ],
            serverParams: {
              columnFilters: {},
              sort: {
                field: "",
                type: "",
              },
              page: 1,
              per_page: 10,
            },
            rows:[]
        }
    },
    methods: {



      download() {
        this.params=this.$route.query.params;
        const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-data-driver-fulfillment`;

        var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        var file_name = "download_fullfilment_mpr" + CurrentDate + ".xlsx";

        //var columnFilters = this.serverParams.columnFilters;

        return this.$http
          .get(
            baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&customer_team=${this.serverParams.columnFilters.customer_team}&truck_category=${this.serverParams.columnFilters.truck_category}&type_truck=${this.serverParams.columnFilters.type_truck}&download=download`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name);
            document.body.appendChild(fileLink);
            fileLink.click();
          
          });
        },

      loadItems() {
            this.isLoading = true;
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-data-driver-fulfillment`;
            //const baseURI = this.$settings.endPointMPR + "control-tower/transport-order-header";
           
            
            return this.$http
              .get(
                 baseURI +
                  `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&customer_team=${this.serverParams.columnFilters.customer_team}&truck_category=${this.serverParams.columnFilters.truck_category}&type_truck=${this.serverParams.columnFilters.type_truck}`,
                { timeout: 10000 }
              )
              .then((response) => { 
                this.rows = response.data.datas;
                this.totalRecords = response.data.datas.total;
                
                this.isLoading = false;
              }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                  this.error(this.$t("check_internet_connection_txt"));
                }else{
      
                  if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push("/authorized-error");
                    }else{
                        this.$router.push('/page-not-found');
                    }
                  }
      
                }
      
              });
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        this.loadItems();
      },
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
      detailData(index,row){
       
        this.showModalDetail=true;
        this.dataSend={
            customer_team:row.customer_team,
            truck_category:row.truck_category,
            type_truck:row.type_truck
        }
      },

      closeMyModalDetail(){
        this.showModalDetail=false;
      }
      
    },
  }
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>