import { render, staticRenderFns } from "./PengajuanMPR.vue?vue&type=template&id=9ab71d4a"
import script from "./PengajuanMPR.vue?vue&type=script&lang=js"
export * from "./PengajuanMPR.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports