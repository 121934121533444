<template>
    <div>
      <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Priority & Status Detail</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <!-- :rows="rows"  -->
              <vue-good-table
                  title="list-order-ct"
                  mode="remote"
                  :totalRows="totalRecords"
                  :line-numbers="true"
                  :pagination-options="{
                      enabled: true,
                  }"
                  :isLoading.sync="isLoading"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :columns="columns"
                  :rows="rows"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <!-- <a style="color: #ab0635;  "
                      title="Insert"
                      @click.prevent="
                        detailData(props.index, props.row)
                      "
                    >
                      <i class="mdi mdi-check" style="font-size:16px"></i>
                      
                    </a> -->
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      name:"modalPriorityStatus",
      emits: ["closeModal","pushData"],
      props: {
        showModal: Boolean,
        data: null,
      },
      watch: {
          showModal: {
          handler(newVal) {
              this.active = newVal;
              const body = document.querySelector("body");
              this.showModal
              ? body.classList.add("modal-open")
              : body.classList.remove("modal-open");
              if(this.showModal==true){
                this.loadItems();
              }
             
            },
          immediate: true,
          deep: true,
          },
      },
      data() {
        return {
            isLoading: false,
            opens: "center",
            active: this.showModal,
            fetchCompany: [],
            totalRecords:0,
            forms:[],
            status:"ASSIGNED",
            columns: [
              {
                label:"Request No",
                field: "request_no",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
              {
                label:"Customer Team",
                field: "customer_team",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
              {
                label:"Status",
                field: "status",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
              {
                label:"Opsi",
                field: "opsi",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By ", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
  
              {
                label:"Project",
                field: "project",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By ", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
  
              {
                label:"Vehicle No",
                field: "vehicle_no",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
  
              {
                label:"Tipe Unit",
                field: "tipe_unit",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"SIM",
                field: "sim",
                filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Jenis Driver",
                field: "jenis_driver",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Location",
                field: "location",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Driver Replace",
                field: "driver_replace",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Kriteria Driver",
                field: "kriteria_driver",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Need Date",
                field: "need_date",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Remarks Request",
                field: "remarks_request",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Priority",
                field: "priority",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
              

              {
                label:"Original Need Date",
                field: "original_need_date",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
              

              {
                label:"Assign Driver",
                field: "assign_driver",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Assign Name Driver",
                field: "assign_name_driver",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },



              {
                label:"Assign Date",
                field: "assign_date",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },


              {
                label:"Age",
                field: "age",
                filterOptions: {
                  enabled: false, // enable filter for this column
                  placeholder: "Filter By", // placeholder for filter input
                  filterValue: "", // initial populated value for this filter
                  filterDropdownItems: [], // dropdown (with selected values) instead of text input
                  trigger: "enter", //only trigger on enter not on keyup
                },
              },
              
              
            ],
            serverParams: {
              columnFilters: {},
              sort: {
                field: "",
                type: "",
              },
              page: 1,
              per_page: 10,
            },
            rows:[]
        }
    },
    methods: {

      loadItems() {
            this.isLoading = true;
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-data-by-status`;
            //const baseURI = this.$settings.endPointMPR + "control-tower/transport-order-header";
           
            console.log(this.serverParams);
            return this.$http
              .get(
                 baseURI +
                  `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&request_no=${this.serverParams.columnFilters.request_no}&customer_team=${this.serverParams.columnFilters.customer_team}&status=${this.serverParams.columnFilters.status}&opsi=${this.serverParams.columnFilters.opsi}&project=${this.serverParams.columnFilters.project}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&tipe_unit=${this.serverParams.columnFilters.tipe_unit}&sim=${this.serverParams.columnFilters.sim}`,
                { timeout: 10000 }
              )
              .then((response) => { 
                this.rows = response.data.datas.data;
                this.totalRecords = response.data.datas.total;
                
                this.isLoading = false;
              }).catch(error => {
                this.isLoading = false;
                if(error.code == "ECONNABORTED"){
                  this.error(this.$t("check_internet_connection_txt"));
                }else{
      
                  if (error.response) {
                    if(error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                    }else if (error.response.status === 500) {
                        this.$router.push('/server-error');
                    }else if (error.response.status === 401) {
                        this.$router.push("/authorized-error");
                    }else{
                        this.$router.push('/page-not-found');
                    }
                  }
      
                }
      
              });
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        this.loadItems();
      },
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
  
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
      detailData(index,row){
        this.$emit('pushData',row);
      }
      
    },
  }
  </script>
  
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left:0px !important;
  }
  </style>