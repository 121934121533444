<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TruckDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail {{ $t("truckTxt") }}
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("vehicle_no") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="vehicle_no"
                              :placeholder="$t('vehicle_no')"
                              v-model="forms.vehicle_no"
                              required
                            />
                            <div v-if="errors.vehicle_no">
                              <div
                                v-for="error in errors.vehicle_no"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("branch_id") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                          
                            <input
                              type="text" readonly
                              class="form-control"
                              id="branch_name"
                              :placeholder="$t('branch_id')"
                              v-model="branch.branch_name"
                            />


                            <div v-if="errors.branch_id">
                              <div
                                v-for="error in errors.branch_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("type_truck") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                         
                            <input
                              type="text" readonly
                              class="form-control"
                              id="type_truck"
                              :placeholder="$t('type_truck')"
                              v-model="type_truck.type_truck"
                            />

                            <div v-if="errors.type_truck_id">
                              <div
                                v-for="error in errors.type_truck_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("truck_brand") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="truck_brand"
                              :placeholder="$t('truck_brand')"
                              v-model="forms.truck_brand"
                            />
                            <div v-if="errors.truck_brand">
                              <div
                                v-for="error in errors.truck_brand"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("truck_model") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="truck_model"
                              :placeholder="$t('truck_model')"
                              v-model="forms.truck_model"
                            />
                            <div v-if="errors.truck_model">
                              <div
                                v-for="error in errors.truck_model"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("truck_status") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="truck_status"
                              :placeholder="$t('truck_status')"
                              v-model="forms.truck_status"
                            />
                            <div v-if="errors.truck_status">
                              <div
                                v-for="error in errors.truck_status"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("km_limit") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="km_limit"
                              :placeholder="$t('km_limit')"
                              v-model="forms.km_limit"
                            />
                            <div v-if="errors.km_limit">
                              <div
                                v-for="error in errors.km_limit"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("engine_number") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="engine_number"
                              :placeholder="$t('engine_number')"
                              v-model="forms.engine_number"
                            />
                            <div v-if="errors.engine_number">
                              <div
                                v-for="error in errors.engine_number"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("vin") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="vin"
                              :placeholder="$t('vin')"
                              v-model="forms.vin"
                            />
                            <div v-if="errors.vin">
                              <div
                                v-for="error in errors.vin"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("name_of_owner") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="name_of_owner"
                              :placeholder="$t('name_of_owner')"
                              v-model="forms.name_of_owner"
                            />
                            <div v-if="errors.name_of_owner">
                              <div
                                v-for="error in errors.name_of_owner"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                          <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("last_km_service") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="last_km_service"
                              :placeholder="$t('last_km_service')"
                              v-model="forms.last_km_service"
                              disabled
                            />
                            <div v-if="errors.last_km_service">
                              <div
                                v-for="error in errors.last_km_service"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("images_txt") }}</label
                            > 
                          </div>
                          <div class="col-lg-8">
                            <div class="dropify-wrapper" v-if="forms.images" >
                              <div class="dropify-message">
                                <img :src="forms.images" width="90%" height="80%"/> 
                             
                              </div>
                            </div>
                            

                             <div v-if="errors.images">
                              <div
                                v-for="error in errors.images"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >PIC</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="pic"
                              readonly
                              v-model="forms.pic"
                            />
                            <div v-if="errors.pic">
                              <div
                                v-for="error in errors.pic"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                            >
                              {{ $t("device_id") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="vehicle_no"
                              :placeholder="$t('device_id')"
                              v-model="forms.device_id"
                              required
                            />
                            <div v-if="errors.device_id">
                              <div
                                v-for="error in errors.device_id"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("ownership") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="ownership"
                              :placeholder="$t('ownership')"
                              v-model="forms.ownership"
                            />
                            <div v-if="errors.ownership">
                              <div
                                v-for="error in errors.ownership"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("carrier") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="truck_model"
                              :placeholder="$t('carrier')"
                              v-model="forms.carrier"
                            />
                            <div v-if="errors.carrier">
                              <div
                                v-for="error in errors.carrier"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("gps_vendor") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="gps_vendor"
                              :placeholder="$t('gps_vendor')"
                              v-model="forms.gps_vendor"
                            />
                            <div v-if="errors.gps_vendor">
                              <div
                                v-for="error in errors.gps_vendor"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("manufacture_year") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="manufacture_year"
                              :placeholder="$t('manufacture_year')"
                              v-model="forms.manufacture_year"
                            />
                            <div v-if="errors.manufacture_year">
                              <div
                                v-for="error in errors.manufacture_year"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("purchased_year") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="purchased_year"
                              :placeholder="$t('purchased_year')"
                              v-model="forms.purchased_year"
                            />
                            <div v-if="errors.purchased_year">
                              <div
                                v-for="error in errors.purchased_year"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("ac_category") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="ac_category"
                              :placeholder="$t('ac_category')"
                              v-model="forms.ac_category"
                            />
                            <div v-if="errors.ac_category">
                              <div
                                v-for="error in errors.ac_category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("box_category") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="box_category"
                              :placeholder="$t('box_category')"
                              v-model="forms.box_category"
                            />
                            <div v-if="errors.box_category">
                              <div
                                v-for="error in errors.box_category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("project") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="project"
                              :placeholder="$t('project')"
                              v-model="forms.project"
                            />
                            <div v-if="errors.project">
                              <div
                                v-for="error in errors.project"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("unit_allocation") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="unit_allocation"
                              :placeholder="$t('unit_allocation')"
                              v-model="forms.unit_allocation"
                            />
                            <div v-if="errors.unit_allocation">
                              <div
                                v-for="error in errors.unit_allocation"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                      

                         <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("last_service") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="last_service"
                              :placeholder="$t('last_service')"
                              v-model="forms.last_service"
                              disabled
                            />
                            <div v-if="errors.last_service">
                              <div
                                v-for="error in errors.last_service"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Billable</label
                            >
                          </div>
                          <div class="col-lg-8">
                            
                             <input
                              type="text"
                              class="form-control"
                              id="billable"
                              v-model="forms.billable"
                              disabled
                            />

                            <div v-if="errors.billable">
                              <div
                                v-for="error in errors.billable"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                            
                          </div>
                        </div>
                         
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{$t('driver_name')}}</label
                            >
                          </div>
                          <div class="col-lg-4">
                            <input
                              type="text"
                              class="form-control"
                              id="driver_id"
                              v-model="forms.driver_id"
                              disabled
                            />
                          </div>
                          <div class="col-lg-4">

                           
                            
                            <input
                              type="text"
                              class="form-control"
                              id="driver_name"
                              v-model="forms.driver_name"
                              disabled
                            />

                            <div v-if="errors.driver_name">
                              <div
                                v-for="error in errors.driver_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                            
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Truck Category</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="truck_category"
                              v-model="forms.truck_category"
                            />
                            <div v-if="errors.truck_category">
                              <div
                                v-for="error in errors.truck_category"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Target Trip</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="target_trip"
                              v-model="forms.target_trip"
                            />
                            <div v-if="errors.target_trip">
                              <div
                                v-for="error in errors.target_trip"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Target Revenue</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text" readonly
                              class="form-control"
                              id="target_revenue"
                              v-model="forms.target_revenue"
                            />
                            <div v-if="errors.target_revenue">
                              <div
                                v-for="error in errors.target_revenue"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                  </form>

                  <!--end::loading-data-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "DriverDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      branch:"",
      fetchDriver:[],
      driver:"",
      type_truck:"",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {},

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/truck/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPointCt + "master-truck/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/truck";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  //this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      window.location.href = "/truck";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-truck/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.branch = response.data.datas.branch
        this.type_truck = response.data.datas.type_truck
        this.forms.driver_name=response.data.datas.driver!=null ? response.data.datas.driver.driver_name : ""
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterTruckController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>