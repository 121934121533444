<template>
  <div>
    <div class="page-content">
      <div class="col-lg-12 col-xl-12 stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="margin-right: 5px" class="float-start">
                  TAMBAH MPR PENGAJUAN
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row my-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Request No</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          readonly
                          :value="requestNo"
                          class="form-control"
                          placeholder="Request No"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Customer Team</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-bind:value="head.customer_team"
                          disabled
                          class="form-control"
                          placeholder="Customer Team"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Jenis Driver</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="jenisDriver"
                          :show-labels="false"
                          :options="fetchJenisDriver"
                          placeholder="Please Select"
                          track-by="value"
                          label="value"
                          
                        ></multiselect>
                        <div v-show="errors.jenis_driver">
                          <div
                            v-for="error in errors.jenis_driver"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Tanggal Dibutuhkan</label
                      >
                      <div class="col-sm-9">
                        <!-- <input
                          type="date"
                          v-model="temp.need_date"
                          class="form-control"
                          placeholder="Customer Team"
                        /> -->
                        <Datepicker
                          v-model="temp.need_date"
                          :disabled-dates="disabledDates"
                          :bootstrap-styling="true"
                          format="dd-MM-yyyy"
                          placeholder="Please Select Date..."
                        ></Datepicker>
                        <div v-if="errors.need_date">
                          <div
                            v-for="error in errors.need_date"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Opsi</label>
                      <div class="col-sm-9">
                        <multiselect
                          v-model="opsi"
                          :show-labels="false"
                          :options="fetchOptions"
                          placeholder="Please Select"
                          track-by="value"
                          label="value"
                        ></multiselect>
                        <div v-if="errors.opsi">
                          <div
                            v-for="error in errors.opsi"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Kriteria Driver</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          multiple
                          v-model="kriteriaDriver"
                          :options="fetchKriteriaDriver"
                          placeholder="Please Select"
                        ></multiselect>
                        <div v-if="errors.kriteria_driver">
                          <div
                            v-for="error in errors.kriteria_driver"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Pengganti Driver</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          :disabled="isActiveFlag"
                          v-model="driverReplaced"
                          :show-labels="false"
                          :options="fetchDriver"
                          placeholder="Please Select"
                          track-by="driver_id"
                          @search-change="asyncSearchDriver"
                          label="driver_id"
                          @input="cariTruckByNmk"
                        >
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.driver_id }} -
                                {{ props.option.driver_name }}</span
                              >
                            </div>
                          </template>
                        </multiselect>
                        <div v-if="errors.driver_replace">
                          <div
                            v-for="error in errors.driver_replace"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Nomor Polisi</label
                      >
                      <div class="col-sm-9">
                        <multiselect
                          v-model="nomorPolisi"
                          :show-labels="false"
                          @search-change="asyncSearchTruck"
                          :options="fetchNomorPolisi"
                          placeholder="Please Select"
                          track-by="vehicle_no"
                          label="vehicle_no"
                          :disabled="jenisDriver?.value=='CADANGAN'"
                          @input="changeNomorPolisi"
                        ></multiselect>
                        <div v-if="errors.vehicle_no">
                          <div
                            v-for="error in errors.vehicle_no"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Tipe Unit</label>
                      <div class="col-sm-9">
                       
                        <multiselect
                          @search-change="asyncTypeTruck"
                          v-model="temp.tipe_unit"
                          track-by="type_truck"
                          label="type_truck"
                          :disabled="jenisDriver?.value!='CADANGAN'"
                          :show-labels="false"
                          :options="fetchTypeTruck"
                          placeholder="Please Select"
                          @input="changeTipeUnit"
                        ></multiselect>

                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Sim</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          v-model="temp.sim"
                          class="form-control"
                          placeholder="Sim"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Lokasi</label>
                      <div class="col-sm-9">
                        <multiselect
                          v-model="temp.location"
                          :show-labels="false"
                          :options="fetchLocation"
                          placeholder="Please Select"
                        ></multiselect>
                        <div v-if="errors.location">
                          <div
                            v-for="error in errors.location"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Project</label>
                      <div class="col-sm-9">
                        <multiselect
                          v-model="project"
                          :show-labels="false"
                          :options="fetchProject"
                          @search-change="asyncLoadCustomer"
                          placeholder="Please Select"
                          track-by="name"
                          label="name"
                        ></multiselect>
                        <div v-if="errors.project">
                          <div
                            v-for="error in errors.project"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Catatan</label>
                      <div class="col-sm-9">
                        <textarea
                          class="form-control"
                          v-model="temp.remarks_request"
                        ></textarea>
                        <div v-if="errors.remarks_request">
                          <div
                            v-for="error in errors.remarks_request"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-warning float-end btn-sm"
                    @click="saveDetail"
                  >
                    Simpan Detail
                  </button>
                </div>
              </div>
            </div>
            <div class="row my-4">
              <div class="card">
                <div class="card-header">
                  <button
                    class="btn btn-success float-end btn-sm"
                    @click="addData"
                    :disabled="details.length <= 0"
                  >
                    Tambah
                  </button>
                </div>
                <div class="card-body">
                  <vue-good-table
                    title="list-user"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :rows="details"
                    :columns="columns"
                    :sort-options="{
                      enabled: false,
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          title="Edit"
                          @click.prevent="editDetail(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                        <a
                          href="#"
                          title="Delete"
                          @click.prevent="deleteDetail(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can"
                            style="font-size: 16px"
                          ></i>
                        </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button
                class="btn btn-warning text-black fw-bolder btn-icon-text"
                @click="backForm"
                type="button"
              >
                <i class="link-icon" data-feather="arrow-left"></i>
                {{ $t("backMess") }}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                class="btn btn-primary float-end btn-sm"
                @click="submitData"
                type="submit"
              >
                <i class="link-icon" data-feather="save"></i>
                {{ $t("submitFormTxt") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  name: "CreatePengajuan",
  components: {
    multiselect: multiselect,
    Datepicker,
  },
  data() {
    return {
      requestNo:
        "MPR_" +
        moment(new Date()).format("YYYYMMDDhms") +
        Math.ceil(Math.random() * 999),
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },
      forms: {},
      head: {
        request_no: null,
        customer_team: null,
      },
      jenisDriver: null,
      fetchJenisDriver: [],
      fetchTypeTruck:[],
      opsi: null,
      fetchOptions: [],
      driverReplaced: null,
      fetchDriver: [],
      nomorPolisi: null,
      fetchNomorPolisi: [],
      location: null,
      fetchLocation: [],
      project: null,
      fetchProject: [],
      kriteriaDriver: null,
      fetchKriteriaDriver: [],
      temp: {
        tipe_unit: null,
        sim: null,
        need_date: null,
      },
      details: [],
      isActiveFlag: true,
      columns: [
        {
          label: "Action",
          field: "actions",
        },
        {
          label: "Request No",
          field: "request_no",
        },
        {
          label: "Jenis Driver",
          field: "jenis_driver",
        },
        {
          label: "Opsi",
          field: "opsi",
        },
        {
          label: "Pengganti",
          field: "driver_replace",
        },
        {
          label: "Nopol",
          field: "vehicle_no",
        },
        {
          label: "Tipe Unit",
          field: "tipe_unit",
        },
        {
          label: "SIM",
          field: "sim",
        },
        {
          label: "Lokasi",
          field: "location",
        },
        {
          label: "Project",
          field: "project",
        },
        {
          label: "Kriteria Driver",
          field: "kriteria_driver",
        },
        {
          label: "Tgl Dibutuhkan",
          field: "need_date",
        },
        {
          label: "Catatan Ops",
          field: "remarks_request",
        },
      ],
      currentIndex: 1,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      type:'add',
    };
  },
  watch: {
    opsi(newData) {
      if(this.type == 'add'){
        this.driverReplaced = null;
      }
      if (newData == null) {
        this.isActiveFlag = true;
        if(this.type == 'edit'){
          this.driverReplaced = null;
        }
      } else {
        if (newData?.value == "PERGANTIAN") {
          this.isActiveFlag = false;
        } else {
          this.isActiveFlag = true;
          if(this.type == 'edit'){
            this.driverReplaced = null;
          }
        }
      }
    },
    jenisDriver(newData){
      if(newData?.value!='CADANGAN'){
        this.temp.tipe_unit = null;
      }else{
        this.temp.sim = null;
        this.temp.tipe_unit = null;
      }
    },
  

    
  },
  methods: {

    changeTipeUnit(ev){
      console.log(ev);
      this.temp.sim=ev.sim;
    },

    submitData() {
      let payload = {};
      payload.request_no = this.requestNo;
      payload.customer_team = this.head?.customer_team;
      payload.details = this.details;
      this.isLoading = true;
      // console.log(this.payload);
      const baseURI = this.$settings.endPointMPR + "v1/mpr/pengajuan/create";
      this.$http
        .post(baseURI, payload)
        .then((response) => {
          this.isLoading = false;
          if (response.data.status === 200) {
            var params = this.$onRandom(response.data.datas.master_mpr_id);
            window.location.href =
              "/mpr-pengajuan/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            //this.resultError(response.data.errors);
            this.loadError(this.errors.message);
          }
        })
        .catch((error) => {
          this.isloading = false;
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            }
          }
        });
    },
    deleteDetail(index) {
      if (this.currentIndex == this.details.length) {
        if (index == this.currentIndex) {
          this.currentIndex--;
          this.clear();
        }
      } else {
        if (this.currentIndex == index) {
          this.clear();
        }
      }
      this.$delete(this.details, index);
    },
    editDetail(index, row) {
      this.type = 'edit',
      this.currentIndex = index;
      this.clear();
      this.jenisDriver = row?.jenis_driver ? { value: row.jenis_driver } : null;
      this.opsi = row?.opsi ? { value: row.opsi } : null;
      this.temp.need_date = row.need_date;
      this.temp.remarks_request = row.remarks_request;
      this.temp.sim=row.sim;
      console.log(row?.driver_replace);
      this.driverReplaced = row?.driver_replace
        ? { driver_id: row.driver_replace }
        : null;
      this.nomorPolisi = row?.vehicle_no
        ? {
            vehicle_no: row.vehicle_no,
            sim: row.sim,
            type_truck: row.tipe_unit,
          }
        : null;
      this.temp.tipe_unit={
        type_truck:row.tipe_unit,
      }
      this.temp.location = row?.location ? row.location : null;
      this.kriteriaDriver = row?.kriteria_driver
        ? row.kriteria_driver.split(";")
        : null;
      this.project = row?.project ? { name: row.project } : null;
    },
    addData() {
      this.currentIndex++;
      this.type = 'add',
      this.clear();
    },
    clear() {
      this.temp = {
        tipe_unit: null,
        sim: null,
        need_date: null,
      };
    
      this.jenisDriver = null;
      this.opsi = null;
      this.driverReplaced = null;
      this.nomorPolisi = null;
      this.location = null;
      this.project = null;
      this.kriteriaDriver = null;
    },

    validateData(data) {
      let res = true;
      this.errors = [];
      if (!data.jenis_driver) {
        this.$set(this.errors, "jenis_driver", ["Empty Value"]);
        res = false;
      }
      if (!data.need_date) {
        this.$set(this.errors, "need_date", ["Empty Value"]);
        res = false;
      }
      if (!data.driver_replace && data?.opsi == "PERGANTIAN") {
        this.$set(this.errors, "driver_replace", ["Empty Value"]);
        res = false;
      }
      if (!data.vehicle_no && data?.jenis_driver == "UTAMA") {
        this.$set(this.errors, "vehicle_no", ["Empty Value"]);
        res = false;
      }
      if (!data.location) {
        this.$set(this.errors, "location", ["Empty Value"]);
        res = false;
      }
      if (!data.project) {
        this.$set(this.errors, "project", ["Empty Value"]);
        res = false;
      }
      if (!data.kriteria_driver) {
        this.$set(this.errors, "kriteria_driver", ["Empty Value"]);
        res = false;
      }
      if (!data.opsi) {
        this.$set(this.errors, "opsi", ["Empty Value"]);
        res = false;
      }

      if (!res) {
        this.resultError(this.errors);
      }

      return res;
    },
    saveDetail() {
      let detail = this.details;
      let temp = { ...this.temp };
      temp.need_date =
        this.temp.need_date ? ( 
        this.temp.need_date.length == 10
          ? this.temp.need_date
          : this.temp.need_date.toISOString().split("T")[0]) : null;

      temp.id = this.currentIndex;
      temp.request_no = this.requestNo;
      temp.vehicle_no = this.nomorPolisi?.vehicle_no;
      temp.jenis_driver = this.jenisDriver?.value;
      temp.tipe_unit=temp.tipe_unit.type_truck;
      temp.opsi = this.opsi?.value;
      temp.kriteria_driver = this.kriteriaDriver
        ? this.kriteriaDriver.join(";")
        : "";
      temp.driver_replace = this.driverReplaced?.driver_id;
      temp.project = this.project?.name;
      if (!this.validateData(temp)) {
        return;
      }
      if (detail.length == 0) {
        detail.push({ ...temp });
        this.currentIndex = detail.length;
      } else {
        // After Save Detail & Not Click Add
        if (detail.length == this.currentIndex) {
          detail.splice(this.currentIndex - 1, 1, temp);
        }
        // Edit Detail
        else if (detail.length > this.currentIndex) {
          detail.splice(this.currentIndex, 1, temp);
        }
        // Total Detail <> CurrentIndex (Add Triggered)
        else {
          this.details.push({ ...temp });
          this.currentIndex = detail.length;
        }
      }

      this.details = detail;
      temp = null;
    },
    loadDriver() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/drivers`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },
    //andez start
    cariTruckByNmk(ev) {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/truck-by-nmk/` + ev.driver_id;
      return this.$http.get(baseURI).then((response) => {
        var data = response.data.datas;
        this.$set(this.temp, 'tipe_unit', { type_truck: data?.type_truck });
        this.$set(this.temp, 'sim', data?.sim);

        //this.$set(this, 'nomorPolisi', { vehicle_no: data?.vehicle_no });
        // Gunakan this.$set agar Vue dapat mendeteksi perubahan properti dalam objek
        
        

        // Jika nomorPolisi sudah dideklarasikan sebelumnya, Vue seharusnya bisa mendeteksi perubahan ini
        //
      });
    },

    changeNomorPolisi(newData) {
      console.log(newData);
      if(newData?.value!='CADANGAN'){
        this.temp.sim = newData.sim;
        this.temp.tipe_unit = {
          type_truck:newData.type_truck
        };
      }
    },


    
    loadTypeTruck() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/type-trucks`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },

    asyncTypeTruck(search) {
      this.fetchTypeTruck= [];
      const baseURI =
        this.$settings.endPointMPR +
        `v1/mpr/lookup/type-trucks?type_trucks=${search}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    //andez end
    asyncSearchDriver(search) {
      this.fetchDriver = [];
      const baseURI =
        this.$settings.endPointMPR +
        `v1/mpr/lookup/drivers?driver_id=${search}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },
    asyncSearchTruck(search) {
      this.fetchNomorPolisi = [];
      const baseURI =
        this.$settings.endPointMPR +
        `v1/mpr/lookup/trucks?customer_team=${this.head.customer_team}&vehicle_no=${search}&empty_driver=true`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchNomorPolisi = response.data.datas.data;
      });
    },

    loadTruck() {
      
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/trucks?customer_team=${this.head.customer_team}&empty_driver=true`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchNomorPolisi = response.data.datas.data;
      });
    },
    loadBranch() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/branch`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas;
      });
    },
    asyncLoadCustomer(search) {
      this.fetchProject = [];
      const baseURI =
        this.$settings.endPointMPR +
        `v1/mpr/lookup/ct-customers?name=${search}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProject = response.data.datas.data;
      });
    },
    loadCustomer() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/ct-customers`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProject = response.data.datas.data;
      });
    },
    loadDriverKriteria() {
      const baseURI =
        this.$settings.endPointMPR + `v1/mpr/lookup/driver-criteria`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchKriteriaDriver = response.data.datas;
      });
    },

    loadJenisDriver() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/jenis-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchJenisDriver = response.data.datas;
      });
    },

    loadOptionDriver() {
      const baseURI = this.$settings.endPointMPR + `v1/mpr/lookup/opsi-driver`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchOptions = response.data.datas;
      });
    },
    loadCustomerTeam() {
      this.fade(true);
      const baseURI =
        this.$settings.endPointMPR + "v1/mpr/lookup/customer-team-by-user";
      this.$http
        .get(baseURI)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.head.customer_team = response?.data?.datas[0];
            this.loadTruck();
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    loadOption() {
      this.loadCustomerTeam();
      this.loadJenisDriver();
      this.loadOptionDriver();
      this.loadDriverKriteria();
      this.loadBranch();
      this.loadCustomer();
      //this.loadTruck();
      this.loadDriver();
      this.loadTypeTruck();
    },
    backForm() {
      window.location.href = "/mpr-pengajuan";
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MprAccessController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },
  mounted() {
    this.fetchIt();
    this.loadOption();
    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "readonly"
          ) {
            const inputs = formElement.querySelectorAll(".multiselect__input");
            inputs.forEach((input) => {
              input.removeAttribute("readonly"); // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll(".multiselect__input");
      inputs.forEach((input) => {
        observer.observe(input, { attributes: true });
      });
    }
  },
};
</script>
