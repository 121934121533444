<template>
    <div>
        <!-- <div class="loader" v-if="this.isLoading"></div>
      <div class="loadertext" v-if="this.isLoading">
        {{ $t("loadingTxt") }}...
      </div> -->
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="Dashboard"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div>
                            <h4 class="mb-3 mb-md-0">Dashboard MPR</h4>
                        </div>

                        <!-- Tombol di sebelah kanan -->
                        <div>
                            <button class="btn btn-primary" @click="openPriotiryStatus">Priority & Status Detail</button>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">

                                    <Doughnut :chart-options="{
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Priority in Progress' // Judul grafik
                                            }
                                            ,datalabels: {
                                                color: 'white', // Warna angka dalam chart menjadi putih
                                                anchor: 'center',
                                                align: 'center',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold'
                                                }
                                            }
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false
                                    }" :chart-data="chartDataPriority" :chart-id="'doughnut-chart'"
                                        :dataset-id-key="'label'" :plugins="[]" :css-classes="''" :styles="{}"
                                        :width="400" :height="400" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <Bar :chart-options="{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: { stacked: true },
                                            y: { stacked: true }
                                        },
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Status'
                                            }
                                            ,datalabels: {
                                                color: 'white', // Warna angka dalam chart menjadi putih
                                                anchor: 'center',
                                                align: 'center',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold'
                                                }
                                            }
                                        }
                                    }" :chart-data="chartDataStatus" class="chart-canvas" />
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">

                                    <div class="d-flex justify-content-between align-items-center flex-wrap ">
                                        <div>
                                            <h4 class="mb-md-0"></h4>
                                        </div>

                                        <!-- Tombol di sebelah kanan -->
                                        <div>
                                            <button class="btn btn-sm btn-primary" @click="openDriverFullfilement">Driver Fullfilment Detail</button>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    


                                    <div class="chart-and-table">
                                        <!-- Chart -->
                                        <div class="chart-container">
                                            <Bar v-if="chartDataDriverFullfiment.labels.length" :chart-options="chartOptionsDriverFullfilment"
                                                :chart-data="chartDataDriverFullfiment" class="chart-canvas-driver-fullfilment" />
                                            <p v-else class="text-center text-gray-500">Loading chart...</p>
                                        </div>

                                        <!-- Table -->
                                        <!-- <div class="table-container mt-4">
                                            <table class="table-auto w-full border-collapse border border-gray-300">
                                                <thead class="bg-gray-100">
                                                    <tr>
                                                        <th class="border border-gray-300 px-4 py-2">Type Truck</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Unit</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                            Settle</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                            Request</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Non Request
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in fetchDriverFullfilment" :key="index">
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.type_truck
                                                            }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.total }}
                                                        </td>
                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                            item.driverSettle }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                            item.driverRequest }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.nonRequest
                                                            }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p v-if="!fetchDriverFullfilment.length"
                                                class="text-center text-gray-500 mt-4">
                                                No data available.
                                            </p>
                                        </div> -->
                                        <div class="table-container mt-4">
                                        <table class="table-auto w-full border-collapse border border-gray-300">
                                            <thead class="bg-gray-100">
                                                <tr>
                                                    <th class="border border-gray-300 px-4 py-2">Category</th>
                                                    <th v-for="(item, index) in fetchDriverFullfilment" :key="'header-' + index" 
                                                        class="border border-gray-300 px-4 py-2">
                                                        {{ item.type_truck }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Unit</td>
                                                    <td v-for="(item, index) in fetchDriverFullfilment" :key="'total-' + index" 
                                                        class="border border-gray-300 px-4 py-2">
                                                        {{ item.total }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Driver Settle</td>
                                                    <td v-for="(item, index) in fetchDriverFullfilment" :key="'driverSettle-' + index" 
                                                        class="border border-gray-300 px-4 py-2">
                                                        {{ item.driverSettle }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Driver Request</td>
                                                    <td v-for="(item, index) in fetchDriverFullfilment" :key="'driverRequest-' + index" 
                                                        class="border border-gray-300 px-4 py-2">
                                                        {{ item.driverRequest }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Non Request</td>
                                                    <td v-for="(item, index) in fetchDriverFullfilment" :key="'nonRequest-' + index" 
                                                        class="border border-gray-300 px-4 py-2">
                                                        {{ item.nonRequest }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p v-if="!fetchDriverFullfilment.length" class="text-center text-gray-500 mt-4">
                                            No data available.
                                        </p>
                                    </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12">
                            <div class="card">
                               
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-md-6">

                                            <Doughnut :chart-options="{
                                            plugins: {
                                            title: {
                                                display: true,
                                                text: 'Driver Fullfilment - By Customer Team ' // Judul grafik
                                            },datalabels: {
                                                color: 'white', // Warna angka dalam chart menjadi putih
                                                anchor: 'center',
                                                align: 'center',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold'
                                                }
                                            }
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false
                                            }" :chart-data="chartDataFullfilmentByTeam" :chart-id="'doughnut-chart'"
                                            :dataset-id-key="'label'" :plugins="[]" :css-classes="''" :styles="{}"
                                            :width="400" :height="400" />

                                            </div>

                                            <div class="col-md-6">
                                                <v-select
                                                :options="fetchTeamList"
                                                v-model="teamList"
                                                class="form-control w-1/3"
                                                label="name"
                                                placeholder="Select Customer Team"
                                                return-object
                                                @input="getByTeamList"
                                                ></v-select>

                                                <div class="mt-3 text-uppercase fw-bold text-center">
                                                    Unit Count
                                                </div>

                                                <div class="mt-3 text-uppercase fw-bold fs-2 text-center">
                                                    {{ totalFulfillment }}
                                                </div>

                                            </div>  

                                    </div>
                                    

                                   
                                </div>
                            </div>
                        </div>

                        

                     

                    </div>




                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12">
                            <div class="card">
                               
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-md-6">

                                           <div class="mt-3 text-uppercase fw-bold text-center">Driver Fullfilment - By Tipe Truck in Customer Team</div>

                                            </div>

                                            <div class="col-md-6">
                                                <v-select
                                                :options="fetchTeamList_"
                                                v-model="teamList_"
                                                class="form-control w-1/3"
                                                label="name"
                                                placeholder="Select Customer Team"
                                                return-object
                                                @input="getByTeamList_"
                                                ></v-select>

                                               

                                            </div>  

                                    </div>


                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col-md-12">
                                            <div class="card">
                                                
                                                <div class="card-body">
                                                    


                                                    <div class="chart-and-table">
                                                        <!-- Chart -->
                                                        <div class="chart-container">
                                                            <Bar v-if="chartDataDriverFullfimentByTeam.labels.length" :chart-options="chartOptionsDriverFullfilmentByTeam"
                                                                :chart-data="chartDataDriverFullfimentByTeam" class="chart-canvas-driver-fullfilment" />
                                                            <p v-else class="text-center text-gray-500">Loading chart...</p>
                                                        </div>

                                                        <!-- Table -->
                                                        <!-- <div class="table-container mt-4">
                                                            <table class="table-auto w-full border-collapse border border-gray-300">
                                                                <thead class="bg-gray-100">
                                                                    <tr>
                                                                        <th class="border border-gray-300 px-4 py-2">Type Truck</th>
                                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Unit</th>
                                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                                            Settle</th>
                                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                                            Request</th>
                                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Non Request
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(item, index) in fetchDriverFullfilment" :key="index">
                                                                        <td class="border border-gray-300 px-4 py-2">{{ item.type_truck
                                                                            }}</td>
                                                                        <td class="border border-gray-300 px-4 py-2">{{ item.total }}
                                                                        </td>
                                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                                            item.driverSettle }}</td>
                                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                                            item.driverRequest }}</td>
                                                                        <td class="border border-gray-300 px-4 py-2">{{ item.nonRequest
                                                                            }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p v-if="!fetchDriverFullfilment.length"
                                                                class="text-center text-gray-500 mt-4">
                                                                No data available.
                                                            </p>
                                                        </div> -->
                                                        <div class="table-container mt-4">
                                                        <table class="table-auto w-full border-collapse border border-gray-300">
                                                            <thead class="bg-gray-100">
                                                                <tr>
                                                                    <th class="border border-gray-300 px-4 py-2">Category</th>
                                                                    <th v-for="(item, index) in fetchDriverFullfilmentByTeamByTipeTruck" :key="'header-' + index" 
                                                                        class="border border-gray-300 px-4 py-2">
                                                                        {{ item.type_truck }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Unit</td>
                                                                    <td v-for="(item, index) in fetchDriverFullfilmentByTeamByTipeTruck" :key="'total-' + index" 
                                                                        class="border border-gray-300 px-4 py-2">
                                                                        {{ item.total }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Driver Settle</td>
                                                                    <td v-for="(item, index) in fetchDriverFullfilmentByTeamByTipeTruck" :key="'driverSettle-' + index" 
                                                                        class="border border-gray-300 px-4 py-2">
                                                                        {{ item.driverSettle }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Driver Request</td>
                                                                    <td v-for="(item, index) in fetchDriverFullfilmentByTeamByTipeTruck" :key="'driverRequest-' + index" 
                                                                        class="border border-gray-300 px-4 py-2">
                                                                        {{ item.driverRequest }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="border border-gray-300 px-4 py-2">Sum Of Non Request</td>
                                                                    <td v-for="(item, index) in fetchDriverFullfilmentByTeamByTipeTruck" :key="'nonRequest-' + index" 
                                                                        class="border border-gray-300 px-4 py-2">
                                                                        {{ item.nonRequest }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <p v-if="!fetchDriverFullfilmentByTeamByTipeTruck.length" class="text-center text-gray-500 mt-4">
                                                            No data available.
                                                        </p>
                                                    </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    

                                   
                                </div>
                            </div>
                        </div>

                        

                     

                    </div>



                </div>

                <modal-priority-status :showModal="showModalPriorityStatus" :data="{}"
                @closeModal="closeMyModal"></modal-priority-status>

                <modal-driver-fullfilment :showModal="showModalDriverFullfilement" :data="{}"
                @closeModal="closeMyModal"></modal-driver-fullfilment>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>
import sidebarComponent from "../_partials/_sidebar";
import navbarComponent from "../_partials/_navbar";
import footerComponent from "../_partials/_footer";
import modalPriorityStatus from "@/components/modal/modalPriorityStatus.vue";
import modalDriverFullfilment from "@/components/modal/modalDriverFullfilment.vue";

import moment from "moment";

// import DateRangePicker from 'vue2-daterange-picker'
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { Doughnut, Bar } from 'vue-chartjs'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)



export default {
    name: "UserDashboard",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        Doughnut, Bar,
        "modal-priority-status":modalPriorityStatus,
        "modal-driver-fullfilment":modalDriverFullfilment
    },
    props: {


    },
    data() {
        return {
            showModalPriorityStatus:false,
            showModalDriverFullfilement:false,
            fetchTeamList:[],
            teamList:'',
            fetchTeamList_:[],
            teamList_:'',
            chartDataDriverFullfiment: {
                labels: [],
                datasets: [
                    {
                        label: "Unit",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Settle",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Non Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                ],
            },
            chartOptionsDriverFullfilment: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: "Driver Fullfilment",
                    },datalabels: {
                        color: 'white', // Warna angka dalam chart menjadi putih
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 8,
                            weight: 'bold'
                        }
                    }
                    
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Type Truck",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Jumlah",
                        },
                    },
                },
            },


            chartDataDriverFullfimentByTeam: {
                labels: [],
                datasets: [
                    {
                        label: "Unit",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Settle",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Non Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                ],
            },
            chartOptionsDriverFullfilmentByTeam: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                     
                    },datalabels: {
                        color: 'white', // Warna angka dalam chart menjadi putih
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 8,
                            weight: 'bold'
                        }
                    }
                    
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Type Truck",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Jumlah",
                        },
                    },
                },
            },

            title: "",
            chartDataStatus: {
                labels: [], // Tanggal
                datasets: [] // Data per status
            },
            chartDataPriority: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            chartDataFullfilmentByTeam: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            fetchPriority: [],
            fetchStatus: [],
            fetchDriverFullfilment: [],
            iframe: "",
            totalFulfillment:0,
            fetchDriverFullfilmentByTeam:[],
            fetchDriverFullfilmentByTeamByTipeTruck:[],
        };
    },
    computed: {
        myStyles() {
            return {
                height: "500px",
                position: "relative",
            };
        },
    },
    watch: {},
    methods: {
        closeMyModal(){
            this.showModalPriorityStatus=false;
            this.showModalDriverFullfilement=false;
        },

        openDriverFullfilement(){
            this.showModalDriverFullfilement=true;
        },

        openPriotiryStatus(){
            console.log('asdsadasd');
            this.showModalPriorityStatus=true;
        },

        formatDate(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        },

        getByTeamList(ev){
            this.loadFullfilmentbyTeam(true,ev);
            console.log(ev)
        },

        getByTeamList_(ev){
            this.loadDriverFullfillmentByTeamByTruck(true,ev);
        },


        getTitleById(id) {
            const item = this.titleData.find(data => data.id === id);
            this.title = item ? item.name : null;
        },

        loadPriority() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-by-priority`;
            this.$http.get(baseURI).then((response) => {
                this.fetchPriority = response.data.datas;

                // Proses data untuk chart
                const labels = this.fetchPriority.map(item => item.priority); // Ambil semua priority
                const data = this.fetchPriority.map(item => item.total); // Ambil semua total

                // Perbarui chartDataPriority
                this.chartDataPriority.labels = labels;
                this.chartDataPriority.datasets[0].data = data;

                const colors = this.fetchPriority.map(() => '#' + Math.floor(Math.random() * 16777215).toString(16));
                this.chartDataPriority.datasets[0].backgroundColor = colors;
            });
        },

        loadFullfilmentbyTeam(filter,text) {
            this.fetchDriverFullfilmentByTeam=[];
            var baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-driver-fulfillment-by-team`;
            if(filter==true){
                baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-driver-fulfillment-by-team?customer_team=`+text;
            }
            
            this.$http.get(baseURI).then((response) => {
                
                this.fetchDriverFullfilmentByTeam = response.data.datas;

                // // Proses data untuk chart
                const labels = this.fetchDriverFullfilmentByTeam.map(item => item.status); // Ambil semua priority
                const data = this.fetchDriverFullfilmentByTeam.map(item => item.total); // Ambil semua total

                // // Perbarui chartDataPriority
                this.chartDataFullfilmentByTeam.labels = labels;
                this.chartDataFullfilmentByTeam.datasets[0].data = data;

                const colors = this.fetchDriverFullfilmentByTeam.map(() => '#' + Math.floor(Math.random() * 16777215).toString(16));
                this.chartDataFullfilmentByTeam.datasets[0].backgroundColor = colors;
                this.totalFulfillment = data.reduce((sum, value) => sum + value, 0);
            });
        },

        loadTeamList() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-customer-team-list`;
            this.$http.get(baseURI).then((response) => {
                this.fetchTeamList = response.data.datas;
                this.fetchTeamList_ = response.data.datas;

            });
        },


        loadDriverFullfillment() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-driver-fulfillment`;
            this.$http.get(baseURI).then((response) => {
                if (!response.data || !response.data.datas) {
                    console.error("Data dari API tidak valid:", response.data);
                    return;
                }

                this.fetchDriverFullfilment = response.data.datas;

                // Pastikan datasets memiliki array dengan index yang cukup
                if (!this.chartDataDriverFullfiment.datasets || this.chartDataDriverFullfiment.datasets.length < 4) {
                    console.error("datasets tidak memiliki index yang cukup", this.chartDataDriverFullfiment.datasets);
                    return;
                }

                this.chartDataDriverFullfiment.labels = this.fetchDriverFullfilment.map(
                    (item) => item.type_truck
                );
                this.chartDataDriverFullfiment.datasets[0].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.total, 10)
                );
                this.chartDataDriverFullfiment.datasets[1].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.driverSettle, 10)
                );
                this.chartDataDriverFullfiment.datasets[2].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.driverRequest, 10)
                );
                this.chartDataDriverFullfiment.datasets[3].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.nonRequest, 10)
                );

                console.log(this.chartDataDriverFullfiment.datasets);
            }).catch((error) => {
                console.error("Gagal mengambil data driver fulfillment:", error);
            });
        },



        loadDriverFullfillmentByTeamByTruck(filter, text) {
            this.fetchDriverFullfilmentByTeamByTipeTruck = [];
            this.chartDataDriverFullfimentByTeam={
                labels: [],
                datasets: [
                    {
                        label: "Unit",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Settle",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Non Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                ],
            };

            let baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-driver-fulfillment-by-truck-type`;
            
            if (filter) {
                baseURI += `?customer_team=${text}`;
            }

            this.$http.get(baseURI).then((response) => {
                if (!response.data.datas || !Array.isArray(response.data.datas)) {
                    console.error("Data dari API tidak valid:", response.data.datas);
                    return;
                }

                // Proses data response
                this.fetchDriverFullfilmentByTeamByTipeTruck = response.data.datas.map(item => {
                    let driverSettle = 0;
                    let driverRequest = 0;
                    let nonRequest = 0;

                    // Jika ada property 'datas', proses nilainya
                    if (Array.isArray(item.datas)) {
                        item.datas.forEach(data => {
                            if (data.status === "DRIVER-SETTLE") {
                                driverSettle = data.total;
                            } else if (data.status === "REQUESTED-MPR") {
                                driverRequest = data.total;
                            } else if (data.status === "NON-REQUESTED MPR") {
                                nonRequest = data.total;
                            }
                        });

                        
                    }

                    return {
                        type_truck: item.type_truck,
                        total: item.total,
                        driverSettle,
                        driverRequest,
                        nonRequest
                    };
                });

                console.log(this.fetchDriverFullfilmentByTeamByTipeTruck);

                // Pastikan dataset dalam chart memiliki struktur yang benar
                if (!this.chartDataDriverFullfimentByTeam.datasets || this.chartDataDriverFullfimentByTeam.datasets.length < 4) {
                    console.error("datasets tidak memiliki index yang cukup", this.chartDataDriverFullfimentByTeam.datasets);
                    return;
                }

                // Update data chart
                this.chartDataDriverFullfimentByTeam.labels = this.fetchDriverFullfilmentByTeamByTipeTruck.map(item => item.type_truck);
                this.chartDataDriverFullfimentByTeam.datasets[0].data = this.fetchDriverFullfilmentByTeamByTipeTruck.map(item => parseInt(item.total, 10));
                this.chartDataDriverFullfimentByTeam.datasets[1].data = this.fetchDriverFullfilmentByTeamByTipeTruck.map(item => parseInt(item.driverSettle, 10));
                this.chartDataDriverFullfimentByTeam.datasets[2].data = this.fetchDriverFullfilmentByTeamByTipeTruck.map(item => parseInt(item.driverRequest, 10));
                this.chartDataDriverFullfimentByTeam.datasets[3].data = this.fetchDriverFullfilmentByTeamByTipeTruck.map(item => parseInt(item.nonRequest, 10));

                console.log("Data chart setelah diproses:", this.chartDataDriverFullfimentByTeam.datasets);
            }).catch((error) => {
                console.error("Gagal mengambil data driver fulfillment:", error);
            });
        },



        loadStatus() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-by-status`;
            this.$http.get(baseURI).then((response) => {
                this.fetchStatus = response.data.datas;

                // Proses data untuk chart
                const labels = [...new Set(this.fetchStatus.map(item => item.date))]; // Daftar tanggal unik
                const statusList = [...new Set(this.fetchStatus.flatMap(item => item.datas.map(data => data.status)))]; // Daftar status unik

                const datasets = statusList.map(status => {
                    return {
                        label: status, // Nama status
                        backgroundColor: this.getRandomColorHexRgb(), // Warna random
                        data: labels.map(date => {
                            const entry = this.fetchStatus.find(item => item.date === date);
                            if (entry) {
                                const statusData = entry.datas.find(data => data.status === status);
                                return statusData ? statusData.total : '';
                            }
                            return 0;
                        }),
                    };
                });

                this.chartDataStatus.labels = labels;
                this.chartDataStatus.datasets = datasets;

                


            });
        },

        getRandomColor() {
            return '#' + Math.floor(Math.random() * 16777215).toString(16);
        },


        generatorColor() {
            var mycolor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
            return mycolor;
        },


        getRandomColorHexRgb() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return "rgb(" + r + "," + g + "," + b + ")";
        },
        getRandomColorHexRgbA() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return "rgba(" + r + "," + g + "," + b + ",0.2)";
        },
        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.isLoading = false;
            }, 1000); // hide the message after 3 seconds
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == 'MprDashboardController') {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            if (!rolePermision.method_read) {
                this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;

        },

        logout() {
            this.$logoutUser();
            this.$router.push("/");
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },



        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loading();
        this.loadPriority();
        this.loadStatus();
        this.loadDriverFullfillment();
        this.loadTeamList();
        this.loadFullfilmentbyTeam(false,'');
        this.loadDriverFullfillmentByTeamByTruck(false,'');

    },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
    visibility: hidden;
}

.blur-content {
    filter: blur(5px);
}

.chart-canvas {
    height: 300px !important;
    /* Atur tinggi sesuai kebutuhan */
    width: 100%;
    /* Pastikan lebarnya fleksibel */
}


.chart-canvas-driver-fullfilment{

    height: 500px !important;
    /* Atur tinggi sesuai kebutuhan */
    width: 100%;

}


.chart-container {
  height: 500px; /* Tinggi untuk grafik */
}

.table-container {
  overflow-x: auto;
}

.table-auto th,
.table-auto td {
  text-align: center;
}

</style>